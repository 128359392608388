import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Icon, Input, Label } from "semantic-ui-react";
import { Col, Container, Row, Stack, Table, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "./GlobalCtx"
import moment from "moment/moment";

export const Pos = () => {
    const navigate = useNavigate()
    const { formatToIDR } = useGlobalContext();
    const [product, setProduct] = useState([])
    const [store, setStore] = useState('PUMA INDONESIA')
    const [trxNumber, setTrxNumber] = useState('PID')
    const [cashier, setCashier] = useState('Cashier 1')
    const [spg, setSpg] = useState('Crew 1')
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [article, setArticle] = useState('')
    const [qty, setQty] = useState([])
    const [total, setTotal] = useState(0)
    const [arrSelProduct, setArrSelProd] = useState([])
    const [dscPercent, setDscPercent] = useState(0)
    const [dscFixed, setDscFixed] = useState(0)
    const [tableData, setTableData] = useState([])
    const [totalCart, setTotalCart] = useState(0)
    const [totalQtySum, setTotalQtySum] = useState(0)
    const [payMethod, setPayMethod] = useState('Cash')
    const [totalDsc, setTotalDsc] = useState(0)
    const footStyle = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100 %',
        backgroundColor: '#333',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
    }

    const infoArea = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100 %',
        backgroundColor: 'whitesmoke',
        color: '#fff',
        textAlign: 'center',
        padding: '40px',
    }

    const getProduct = async (art) => {
        await axios({
            url: `${global.srv}pos-app/controler/pos.php?i=1&art=${art}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                let newData = []
                setDscFixed(0)
                setDscPercent(0)
                setQty(1)
                res.data.map(o =>
                    newData.push({
                        article: o.article,
                        barcode: o.barcode,
                        product_name: o.product_name,
                        size: o.size,
                        price: o.price,
                        qty: 1,
                        dsc_percent: 0,
                        dsc_fixed: 0,
                        total: 1 * parseFloat(o.price),
                    })
                )
                setProduct(newData)
                res.data.map((o, i) =>
                    setTotal(o.price * qty)
                )
            })
    }

    const handleAddSelected = (event, row) => {
        const existIndex = arrSelProduct.findIndex(o => o.art === row.article && o.size === row.size);
        const newItem = {
            art: row.article,
            product_name: row.product_name,
            size: row.size,
            price: row.price,
            discount: dscPercent > 0 ? row.price * dscPercent / 100 : parseFloat(dscFixed),
            qty: parseInt(qty),
            total: row.total,
        };

        if (existIndex !== -1) {
            // Update item jika sudah ada
            setArrSelProd(cur => {
                const updatedArr = [...cur];
                updatedArr[existIndex] = newItem;
                return updatedArr;
            });
        } else {
            // Tambahkan item baru jika belum ada
            setArrSelProd(cur => [...cur, newItem]);
        }
    }

    const handleChangeQty = (qty, item) => {
        setQty(qty)

        let newData = product.map((o, i) => {
            if (o.size == item.size && o.article == item.article) {
                return {
                    ...o,
                    qty: parseInt(qty),
                    total: (() => {
                        if (o.dsc_fixed > 0) {
                            return (o.price - o.dsc_fixed) * qty
                        } else if (o.dsc_percent > 0) {
                            return (o.price - ((o.price * o.dsc_percent) / 100)) * qty
                        } else {
                            return o.price * qty
                        }
                    })()
                }
            }
            return o
        })

        setProduct(newData)
    }

    const handleChangeDscPer = (dscPer, item) => {
        console.log(dscPer)
        setDscPercent(dscPer)
        setDscFixed(0)
        let newData = product.map((o, i) => {
            if (o.size == item.size && o.article == item.article) {
                return {
                    ...o,
                    dsc_percent: parseInt(dscPer),
                    dsc_fixed: 0,
                    total_dsc: totalDsc,
                    total: o.price - ((o.price * dscPer) / 100) * o.qty
                }
            }
            return o
        })
        setProduct(newData)
    }

    const handleChangeDscFixed = (dscFix, item) => {
        setDscPercent(0)
        setDscFixed(parseFloat(dscFix))
        let newData = product.map((o, i) => {
            if (o.size == item.size && o.article == item.article) {
                return {
                    ...o,
                    dsc_fixed: parseInt(dscFix),
                    dsc_percent: 0,
                    total: (o.price - dscFix) * qty
                }
            }
            return o
        })
        setProduct(newData)
    }

    const removeItem = (art, size) => {
        setArrSelProd(arrSelProduct.filter(o => o.size !== size))
    }

    const handlePrint = () => {
        let data = {
            header: {
                store: store,
                trx_number: trxNumber,
                cashier: cashier,
                spg: spg,
                method: payMethod,
                date: date,
                total_dsc: arrSelProduct.reduce((p, c) => {
                    return parseFloat(p) + parseFloat(c.discount)
                }, 0),
                subtotal: arrSelProduct.reduce((p, c) => {
                    return parseFloat(p) + parseFloat(c.price)
                }, 0),
                totalQty: arrSelProduct.reduce((p, c) => {
                    return parseFloat(p) + parseFloat(c.qty)
                }, 0),
                grandTotal: arrSelProduct.reduce((p, c) => {
                    return parseFloat(p) + parseFloat(c.total)
                }, 0) + 0 // discount
            },
            detail: arrSelProduct,
        }

        navigate('/print', { state: data })
    }

    useEffect(() => {
        setTotalCart(arrSelProduct.reduce((a, c) => { return a + c.total }, 0))
        setTotalQtySum(arrSelProduct.reduce((a, c) => { return a + c.qty }, 0))
    }, [arrSelProduct])
    console.log(arrSelProduct)
    return (
        <Container fluid="md" className="">
            <div className="display-5 mt-3" style={{color:'teal'}}>POS Print</div>
            <div>This POS was created only to print transaction receipts</div>
            <Row className="pt-2 d-flex justify-content-center">
                <Col md={12} className="mb-3">
                    <Card style={{borderColor:'#38C088', borderWidth:'2px'}}>
                        <Card.Body>
                        <Form >
                        <Form.Group>
                            <Form.Input type="text" label="Store Name" value={store} onChange={(e) => setStore(e.target.value)} width={4} />
                            <Form.Input type="text" label="Transaction Number" value={trxNumber} onChange={(e) => setTrxNumber(e.target.value)} width={2} />
                            <Form.Input type="text" label="Cashier" value={cashier} onChange={(e) => setCashier(e.target.value)} />
                            <Form.Input type="text" label="SPG/B" value={spg} onChange={(e) => setSpg(e.target.value)} />
                            <Form.Field label='Payment Method' control='select' onChange={(e) => setPayMethod(e.target.value)}>
                                <option value="Cash">Cash</option>
                                <option value="Card">Card</option>
                                <option value="Split Payment">Split Payment</option>
                            </Form.Field>
                            <Form.Input type="date" label="Date" value={date} onChange={(e) => setDate(e.target.value)} />

                        </Form.Group>
                    </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="vh-100">
                <Col lg={8} md={12}>
                    <Card style={{borderColor:'#38C088', borderWidth:'2px'}}>
                        <Card.Body>
                            <Col md={12} className="mb-5">
                                <Form onSubmit={() => getProduct(article)}>
                                    <Form.Field width={3}>
                                        <div className="fs-6 fw-bold mb-2"> <Icon name="box" />&nbsp;Article</div>
                                        <Input type="text" placeholder="Article" onChange={(e) => setArticle(e.target.value)} className="me-4" icon='search' />
                                    </Form.Field>
                                </Form>
                            </Col>
                            <Col md={12} className="mb-5" >
                                <Table border hover style={{ width: '100%' }}>
                                    <thead className="custom-table-header">
                                        <tr >
                                            <th>#</th>
                                            <th>Product</th>
                                            <th>Size</th>
                                            <th>Price</th>
                                            <th>D.Percent</th>
                                            <th>D.Fixed</th>
                                            <th>Qty</th>
                                            <th>Total</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            product.length > 0 ?
                                                product.map((o, i) => {
                                                    return (
                                                        <tr className="align-middle" key={i}>
                                                            <td>{i + 1}</td>
                                                            <td style={{fontSize:'0.9em'}}>
                                                                <div className="fw-bold text-muted">{o.article}</div>
                                                                <div className="fw-bold" style={{color:'teal'}}>{o.product_name}</div>
                                                            </td>
                                                            <td>{o.size}</td>
                                                            <td>{formatToIDR(o.price)}</td>
                                                            <td>
                                                                <Input style={{ maxWidth: '70px' }} type="number" value={o.dsc_percent} size="mini" onChange={(e) => handleChangeDscPer(e.target.value, o)} />
                                                            </td>
                                                            <td>
                                                                <Input type="number" value={o.dsc_fixed} size="mini" onChange={(e) => handleChangeDscFixed(e.target.value, o)} />
                                                            </td>
                                                            <td>
                                                                <Input style={{ maxWidth: '60px' }} type="number" value={o.qty} size="mini" onChange={(e) => handleChangeQty(e.target.value, o)} />
                                                            </td>
                                                            <td>{formatToIDR(o.total)}</td>
                                                            <td>
                                                                <Button color="teal" size="small" icon="plus" onClick={(e) => handleAddSelected(e, o)} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr><td colSpan='10' className="text-center">No data..!</td></tr>
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card style={{borderColor:'#38C088', borderWidth:'2px'}} className="mb-3">
                        <Card.Body>
                            <h4><Icon name="cart" />&nbsp;Product Cart</h4>
                            {
                                arrSelProduct.length > 0 ?
                                    arrSelProduct.map((o, i) =>
                                        <Col lg={12} key={i} className="mb-4">
                                            <Stack direction="vertical" gap={2}>
                                                <div key={i} className="border rounded p-3" >
                                                    <Row>
                                                        <Col sm={10}>
                                                            <div className="small">{o.art}</div>
                                                            <div className="fw-bold" style={{color:'teal'}}>{o.product_name}</div>
                                                            <div className="fw-bold">{formatToIDR(o.price)} - {o.discount}</div>
                                                            <Stack direction="horizontal" gap={3} className="small fs-6" >
                                                                <div>Size : {o.size}</div>
                                                                <div>Qty : {o.qty}</div>
                                                                <div>Total : {formatToIDR(o.total)}</div>
                                                            </Stack>
                                                        </Col>
                                                        <Col sm={2} className="text-end">
                                                            <Button basic icon="close" size="mini" onClick={() => removeItem(o.art, o.size)} color="teal"/>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </Stack>
                                        </Col>
                                    )
                                    :
                                    <div className="text-center">
                                        Empty cart ...
                                    </div>
                            }
                        </Card.Body>
                    </Card>
                    <Card style={{borderColor:'#38C088', borderWidth:'2px'}}>
                        <Card.Body className="text-end">
                            <Label className="me-3" content={totalQtySum} icon="box" size="big" color="teal"/>
                            <Label content={formatToIDR(totalCart)} icon="cart" size="big" color="teal"/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
                <Col md={12} className="text-center">
                    <div style={footStyle} className="w-100">
                        <Row className="align-items-center">
                            <Col md={10} className="d-flex justify-content-start">
                                <small>@2023 by JFS Developer</small>
                            </Col>
                            <Col md={2} className="d-flex justify-content-end">
                                <Button content="Print Preview" onClick={() => handlePrint()} icon="sticky note outline" fluid size="large" color="teal" />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}