import { createContext, useContext, useState } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [formattedPrice, setFormattedPrice] = useState("");

  // Fungsi untuk memformat nilai ke format Rupiah
  const formatToIDR = (value) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits:0
    }).format(value);
  };

  return (
    <GlobalContext.Provider value={{ formattedPrice, setFormattedPrice, formatToIDR }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
      throw new Error("useGlobalContext must be used within a GlobalProvider");
    }
    return context;
  };