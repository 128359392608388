import logo from './logo.svg';
import './App.css';
import { Pos } from './view/Pos';
import {Route, HashRouter, Routes} from 'react-router-dom'
import { Print } from './view/Print';


function App() {
  global.srv = "https://javafootwear.id/"
  // global.srv = "http://localhost/"
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path='/' element={<Pos/>}/>
          <Route path='/print' element={<Print/>}/>
        </Routes>
      </HashRouter>
      
    </div>
  );
}

export default App;
