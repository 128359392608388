import React, { Fragment } from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Button, Container, Divider } from "semantic-ui-react";
import { useGlobalContext } from "./GlobalCtx"
import moment from "moment/moment";
import hoopsLogo from "../asset/hoops_logo.png";
import pumaLogo from "../asset/PUMA LOGO.png";

export const Print = () => {
    const location = useLocation()
    const { formatToIDR } = useGlobalContext();
    const componentRef = React.useRef()
    let time = moment().utcOffset('+07').format('hh:mm:ss')

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const style = {
        position: 'absolute',
        top: '28%',
        left: '23%',
        zIndex: 'auto',
        letterSpacing: '40px',
        transform: "rotate(-35deg)",
        opacity: '0.1',
        fontSize: '7em',
        fontWeight: 'bold',
    }

    const PrintComponent = React.forwardRef((props, ref) => {
        let data = props.data
        console.log(data)
        return (
            <Fragment >
                <div style={{width:'30%'}}>
                    <div ref={ref} style={{paddingTop:0, paddingRight:25, paddingLeft:30, fontFamily:'monospace', fontSize:'12px', lineHeight:'1.2' }}>
                        <div>
                            <Row className="d-flex justify-content-center">
                                <Col  md={12} className="text-center mb-5">
                                    <div><Image src={pumaLogo} width={125} height={70} className="mb-3"/></div>
                                    <div className="fw-bold fs-4 mb-2">{data.header.store}</div>
                                    {/* <div className="fs-6">Summarecon Villagio I-102 & I-103</div> */}
                                </Col>
                                <Col md={12} className="text-start">
                                    <div>PT. JAVA FOOTWEAR SPORTINDO</div>
                                    <div>NPWP : </div>
                                    <Divider/>
                                    <Stack direction="horizontal" gap={3}>
                                        <div>Date : {data.header.date}</div>
                                        <div>Time : {time}</div>
                                    </Stack>
                                    <div>Cashier : {data.header.cashier}</div>
                                    <div>SPG/SPB : {data.header.spg}</div>
                                    <Divider/>
                                    {
                                        data.detail.map((o,i) => 
                                            <Stack direction="vertical" gap={2} className="mb-3" style={{lineHeight:1}}>
                                                <Row>
                                                    <Col xs={5}><span > {o.art} </span></Col>
                                                    <Col xs={1} className="text-end"><span >{o.size} </span></Col>
                                                    <Col xs={6} className="text-end"><span> {o.product_name} </span></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={1}><span className="me-3">(x{o.qty})</span></Col>
                                                    <Col xs={5} className="text-end"><span >{formatToIDR(o.price)} - {formatToIDR(o.discount)}</span></Col>
                                                    <Col xs={6} className="text-end"><span >{formatToIDR(o.total)}</span></Col>
                                                </Row>
                                            </Stack>
                                        )
                                    }

                                    <Divider/>
                                    <Stack direction="vertical" gap={0}>
                                        <Row>
                                            <Col xs={6}>
                                                <div>Subtotal : </div>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <div>{formatToIDR(data.header.subtotal)}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <div>Total Discount : </div>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <div>-{formatToIDR(data.header.total_dsc)}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <div>Item Purchase : </div>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <div>(x{data.header.totalQty})</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <div>Grand Total : </div>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <div>{formatToIDR(data.header.grandTotal)}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <div>Payment Method : </div>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <div>{data.header.method}</div>
                                            </Col>
                                        </Row>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col xs={12} className="text-center">
                                    <p>HARGA SUDAH TERMASUK PPN</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    })

    return (
        <Container fluid>
            <Row className="mt-1 mb-3 d-flex">
                <div className="text-facebook h3 mb-0 mt-2 text-center">Transaction Note Pre    view</div>
            </Row>
            <Divider />
            <Row className="mb-3">
                <Col lg={6}>

                </Col>
                <Col lg={6} className='d-flex justify-content-end'>
                    <Button content="Print" icon="print" onClick={handlePrint} color="teal"/>
                </Col>
            </Row>
            {
                location ?
                    <PrintComponent ref={componentRef} data={location.state} />
                    : <>Loading...</>
            }
        </Container>
    )
}